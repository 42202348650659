/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('pageChanger', {
    controller: pageChangerCtrl,
    controllerAs: 'vm',
    template: require('$views/components/page-changer.html').default,
    bindings: {
      page: '<',
      pagesCount: '<',
      setPage: '&'
    }
  });


function pageChangerCtrl () {
  var vm = this;

  vm.$onInit = function () {
    vm.selectedPage = vm.page;
  };

  vm.$onChanges = function (changes) {
    if (changes.page) {
      vm.selectedPage = changes.page.currentValue;
    }
  };

  vm.changePage = function () {
    updatePage(vm.page);
  };

  vm.nextPage = function () {
    updatePage(vm.selectedPage + 1);
  };

  vm.prevPage = function () {
    updatePage(vm.selectedPage - 1);
  };

  function validPage(page) {
    return page > 0 && page <= vm.pagesCount;
  }

  function updatePage(page) {
    if (!validPage(page)) { return; }
    vm.selectedPage = page;
    vm.setPage({page: page});
  }
}


