/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('dateRangeSelectPicker', {
      controller: dateRangeSelectPickerComponent,
      template: require('$views/components/date-range-select-picker.html').default,
      controllerAs: 'self',
      bindings: {
        onChange: '&',
        dateRangeId: '=',
        dateRangeIds: '<',
        additonalDateRangeIds: '<',
        inAdmin: '<'
      }
    });

dateRangeSelectPickerComponent.$inject = ['time'];
function dateRangeSelectPickerComponent (time) {
  var self = this;
  var defaultDateRangeIds = [
    {key: 'yesterday', value: 'Yesterday'},
    {key: 'last_7_days', value: 'Last 7 days'},
    {key: 'this_week', value: 'This week'},
    {key: 'last_week', value: 'Last week'},
    {key: 'last_14_days', value: 'Last 14 days'},
    {key: 'this_month', value: 'This month'},
    {key: 'last_30_days', value: 'Last 30 days'},
    {key: 'last_month', value: 'Last month'}
  ];

  self.$onInit = function () {
    if (!self.dateRangeIds) {
      self.dateRangeIds = defaultDateRangeIds;
    }

    if (self.additonalDateRangeIds) {
      self.dateRangeIds = self.additonalDateRangeIds.concat(self.dateRangeIds);
    }

  };

  self.$onChanges = function () {
    if (self.dateRangeId) {
      self.setDateRange();
    }
  };

  self.setDateRange = function() {
    var dateRange = time.getDateRange(self.dateRangeId);
    self.onChange({
      startDate: dateRange.min,
      endDate: dateRange.max
    });
  };

}


