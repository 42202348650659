/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('dateRangeCalendar', {
      controller: dateRangeCalendarComponent,
      template: require('$views/components/date-range-calendar.html').default,
      controllerAs: 'self',
      bindings: {
        onChange: '&',
        startDate: '<',
        endDate: '<',
        inAdmin: '<'
      }
    });

dateRangeCalendarComponent.$inject = ['time'];
function dateRangeCalendarComponent (time) {
  var self = this;
  var dateRegex = /^(0[1-9]|1[012]) ?[\/\-] ?(0[1-9]|[12][0-9]|3[01]) ?[\/\-] ?\d{4}$/;

  self.$onInit = function () {
    self.startOldDate = self.startDate;
    self.endOldDate = self.endDate;
    self.isOpen = false;
  };

  self.$doCheck = function () {
    // date picker is too old - we must implemented own $onChanges()
    if (self.startOldDate !== self.startDate) {
      setRawDate('start');
    }
    if (self.endOldDate !== self.endDate) {
      setRawDate('end');
    }
    self.startOldDate = self.startDate;
    self.endOldDate = self.endDate;
  };

  self.setDateRange = function () {
    self.isOpen = false;
    self.onChange({
      startDate: self.startDate,
      endDate: self.endDate
    });
  };

  self.setDateFromRaw = function (key) {
    var rawDate = self[key + 'RawDate'];
    if (!dateRegex.test(rawDate)) { return; }
    var date = time.checkTimeAndReplace(rawDate);
    if (date !== null) {
      self[key + 'Date'] = date;
    }
  };

  function setRawDate(key) {
    self[key + 'RawDate'] = time.getAmericanDate(self[key + 'Date']);
  }

}

